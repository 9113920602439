@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  overflow-x: hidden;
}

/**
 * Old version CSS
 */

@font-face {
  font-family: 'DM Sans';
  src: URL('../public/assets/fonts/DMSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans';
  src: URL('../public/assets/fonts/DMSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'DM Sans';
  src: URL('../public/assets/fonts/DMSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'DM Sans';
  src: URL('../public/assets/fonts/DMSans-Medium.ttf') format('truetype');
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans';
  src: URL('../public/assets/fonts/DMSans-MediumItalic.ttf') format('truetype');
  font-weight: medium;
  font-style: italic;
}

@font-face {
  font-family: 'DM Sans';
  src: URL('../public/assets/fonts/DMSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-Black.ttf') format('truetype');
  font-weight: black;
  font-style: normal;
}

@font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-BlackItalic.ttf') format('truetype');
  font-weight: black;
  font-style: italic;
}

@font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-DemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-DemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-Extrabold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-ExtraboldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-Extralight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-ExtralightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-Light.ttf') format('truetype');
  font-weight: light;
  font-style: normal;
}

@font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-LightItalic.ttf') format('truetype');
  font-weight: light;
  font-style: italic;
}

@font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-Medium.ttf') format('truetype');
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-MediumItalic.ttf') format('truetype');
  font-weight: medium;
  font-style: italic;
}

@font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-RegularOffset.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */
/* @font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-RegularOffsetItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */
@font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-Thin.ttf') format('truetype');
  font-weight: thin;
  font-style: normal;
}

@font-face {
  font-family: 'EastmanCompressed';
  src: URL('../public/assets/fonts/EastmanCompressed-ThinItalic.ttf') format('truetype');
  font-weight: thin;
  font-style: italic;
}


/* Define a CSS class to use the EastmanCompressed-Heavy font */
.eastman-compressed {
  font-family: 'EastmanCompressed', sans-serif;
}

.dm-sans {
  font-family: 'DM Sans', sans-serif;
}

.section-container {
  /* padding: 10px; */
  position: relative;
}

.section-container-1 {
  z-index: 1;
}

.section-container-2 {
  z-index: 2;
}

.section-container-3 {
  z-index: 3;
}

.section-container-4 {
  z-index: 4;
}

.section-container-5 {
  z-index: 5;
}

.section-container-6 {
  z-index: 6;
}

.section-container-7 {
  z-index: 7;
}

.section-container-8 {
  z-index: 8;
}

.section-container-9 {
  z-index: 9;
}

.section-container-10 {
  z-index: 10;
}

.section-container-11 {
  z-index: 11;
}

.section-container-12 {
  z-index: 12;
}

/* Full Screen Menu */
.fullscreen-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  padding-right: 19.16%;
  padding-left: 19.16%;
  padding-top: 110px;
  opacity: 1;
  /* Add transition for opacity */
}

@media only screen and (max-width: 768px) {
  .fullscreen-menu {
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 86px;
  }
}

/* Hidden Menu */
.hidden-menu {
  opacity: 0;
  pointer-events: none;
  /* Prevent interaction with hidden menu */
}

/* Menu Title */
.menu-title {
  font-size: 1.875rem;
  margin-bottom: 10px;
  color: transparent;
  -webkit-text-stroke: 1px white;
}

@media only screen and (max-width: 768px) {
  .menu-title {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
}

/* Menu Items */
.menu-items {
  list-style-type: none;
  padding: 0;
  font-size: 4.375rem;
}

/* Close Button */
.close-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.875rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

/* Define custom font sizes for H1 and H2 in pixels */
.text-custom-h1 {
  line-height: 1.15;
  font-size: 8.125rem;
  letter-spacing: 3px;
}

/* Define custom font size for the fourth section text */
.text-custom-h2 {
  font-size: 6.25rem;
}

.footer-title {
  font-size: 6.25rem;
}

.text-custom-h3 {
  font-family: 'EastmanCompressed';
  font-size: 5rem;
}

.text-custom-h4 {
  font-size: 2.5rem;
}

/* Define a CSS class to use the DM Sans font with bold weight and 32px font size */
.text-custom-h5 {
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 40px;
}


/* Define transparent text with black 1px border */
.text-transparent-border {
  font-size: 5rem;
  color: transparent;
  -webkit-text-stroke: 1px black;
}

.text-transparent-border-white {
  font-size: 5rem;
  color: transparent;
  -webkit-text-stroke: 1px white;
}

/* Position and style the hand-stroke circle */
.circle-stroke {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-30%);
  ;
  z-index: -1;
}

/* Define custom font size for the content text */
.text-custom-h6 {
  font-family: 'DM Sans';
  font-size: 1.5rem;
}

.circle-svg-img {
  max-width: 90vw;
}

@media only screen and (max-width: 768px) {
  .menu-items {
    font-size: 1.875rem;
  }

  /* Define custom font sizes for H1 and H2 in pixels */
  .text-custom-h1 {
    font-size: 3.625rem;
  }

  .text-custom-h2 {
    font-size: 1.5625rem;
  }

  .footer-title {
    font-size: 2.8125rem;
  }

  .text-custom-h3 {
    font-size: 3.4375rem;
  }

  .text-custom-h4 {
    font-size: 1.5625rem;
  }

  /* Define a CSS class to use the DM Sans font with bold weight and 32px font size */
  .text-custom-h5 {
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 1.1875rem;
    line-height: 20px;
  }

  /* Define custom font size for the content text */
  .text-custom-h6 {
    font-size: 1.125rem;
  }

  .video-container {
    margin: 14px;
  }

  footer>p {
    font-size: 0.6875rem;
  }
}

footer {
  font-family: 'DM Sans';
  font-weight: normal;
  font-size: 0.875rem;
  background: white;
  z-index: 11;
  top: -1px;
}

@media only screen and (max-width: 720px) {
  footer * {
    font-size: 0.6875rem;
  }
}

@media only screen and (max-width: 640px) {
  footer a {
    font-family: 'EastmanCompressed';
    font-size: 0.9375rem;
    /* font-weight: 900; */
  }
}


#footer-overlay {
  z-index: 12;
}

.hard-hidden {
  display: none !important;
}

.sm-hidden {
  display: block;
}

@media only screen and (max-width: 639px) {
  .sm-hidden {
    display: none;
  }
}

.less-packing-letter-style {
  letter-spacing: 2.5px;
  line-height: 1.5;
}

@media only screen and (max-width: 768px) {
  .less-packing-letter-style {
    line-height: 2;
  }
}

.section-2-text-style {
  width: 100%;
  max-width: 868px;
  letter-spacing: 2.2px;
  padding: 0 60px;
}

@media only screen and (max-width: 768px) {
  .section-2-text-style {
    letter-spacing: 0.8px;
    line-height: 1.6;
  }
}

#what-we-are-doing {
  letter-spacing: 2px;
  font-size: min(180px, 11.25rem);
}

@media only screen and (max-width: 768px) {
  #what-we-are-doing {
    letter-spacing: 1px;
    font-size: 3.875rem;
  }
}

.section-4-text-1 {
  line-height: 1.1;
  font-size: 5.625rem;
}

.section-4-text-2 {
  line-height: 1.2;
  font-size: 5rem;
}

@media only screen and (max-width: 768px) {
  .section-4-text-1 {
    /* margin-top: 80px; */
    font-size: 4.9375rem;
  }

  .section-4-text-2 {
    font-size: 2.8125rem;
  }
}

.section-5-title-1 {
  font-size: 5.625rem;
}

.section-5-title-2 {
  font-size: 5rem;
}

@media only screen and (max-width: 768px) {
  .section-5-title-1 {
    font-size: 3.4375rem;
  }

  .section-5-title-2 {
    font-size: 3.4375rem;
  }
}

.container-ring-1 {
  z-index: 2;
  width: 400px;
}

.container-ring-2 {
  z-index: 2;
  width: 600px;
}

.container-ring-3 {
  z-index: 2;
  width: 800px;
}

@media only screen and (max-width: 768px) {
  .container-ring-1 {
    z-index: 2;
    width: 220px;
  }

  .container-ring-2 {
    z-index: 2;
    width: 280px;
  }

  .container-ring-3 {
    z-index: 2;
    width: 340px;
  }
}

.section-fix-full-10x {
  width: 1000%;
  height: 1000%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .section-fix-full-10x {
    width: 900%;
    height: 900%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.section-6-text-1 {
  font-family: 'EastmanCompressed';
  font-size: 5.3125rem;
  line-height: 1.1;
}

.section-6-text-2 {
  font-family: 'EastmanCompressed';
  font-size: 5rem;
  line-height: 1.2;
}

@media only screen and (max-width: 768px) {
  .section-6-text-1 {
    font-size: 3.4375rem;
  }

  .section-6-text-2 {
    font-size: 2.8125rem;
  }
}

.section-9-text-1 {
  font-family: 'EastmanCompressed';
  font-size: 5rem;
  line-height: 1.2;
}

.section-9-text-2 {
  font-family: 'EastmanCompressed';
  font-size: 5.3125rem;
  line-height: 1.1;
}

@media only screen and (max-width: 768px) {
  .section-9-text-1 {
    font-family: 'EastmanCompressed';
    font-size: 2.8125rem;
  }

  .section-9-text-2 {
    font-family: 'EastmanCompressed';
    font-size: 4.6875rem;
  }

}

.section-10-title-1 {
  font-family: 'EastmanCompressed';
  font-size: 5rem;
}

@media only screen and (max-width: 768px) {
  .section-10-title-1 {
    font-family: 'EastmanCompressed';
    font-size: 2.8125rem;
  }
}

#footer-bottle {
  width: 184px;
}

@media only screen and (max-width: 768px) {
  #footer-bottle {
    width: 100px;
  }
}

/* Define custom font size for the fourth section text */
.footer-bottle-text-1 {
  font-size: 6.25rem;
}


@media only screen and (max-width: 768px) {
  .footer-bottle-text-1 {
    font-size: 2.8125rem;
  }
}

#video-play-icon {
  z-index: 5;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 116px;
  height: 116px;
  margin-left: -58px;
  margin-top: -58px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
}

#video-play-icon:hover {
  opacity: 0.8;
}

@media only screen and (max-width: 768px) {
  #video-play-icon {
    width: 40px;
    height: 40px;
    margin-left: -20px;
    margin-top: -20px
  }
}

#open-menu, #open-lang-menu {
  z-index: 49;
}

/**
 * Old animate css
 */
.lead-circle {
  z-index: 21;
  background-color: rgb(0,0,0);
  width: 18px;
  height: 18px;
  border-radius: 9px;
  position: fixed;
  /* top: -50px; */
  left: 50%;
  margin-left: -9px;
}

.lead-circle-1 {
  background: white;
}

.lead-circle-8 {
  position: absolute;
  width: 2500px;
  height: 2500px;
  border-radius: 1250px;
  margin-top: -1250px;
  margin-left: -1250px;
  /* top: 65vh; */
}

.scroll-overlay-1 {
  /* origina + 1 */
  z-index: 2;
  background: black;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  transform-origin: bottom;
  /* transition: height 0.25s ease-in; */
}

.scroll-overlay-2 {
  /* origina + 1 */
  z-index: 3;
  background: white;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  /* transition: height 0.25s ease-in; */
  transform-origin: bottom;
}

.section-container {
  /* position: ; */
  top: 0;
  width: 100%;
  /* height: 100vh; */
  min-height: 100vh;
  /* padding-top: 220px; */
  background: white;
}

.section-container-1 {
  background: black;
  /* height: 118vh; */
}

.section-container-11 {
  height: 150vh;
  min-height: -webkit-fill-available;
  padding: 0;
}

.section-container-8 {
  height: 150vh;
  overflow: visible;
}

.section-fix-full {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exit-curve {
  z-index: 3;
  width: 100%;
  position: absolute;
  bottom: -1px;
  min-height: 120px;
}

@media only screen and (max-width: 768px) {
  .exit-curve {
    min-height: 40px;
  }
}

.image-background-and-cover-config {
  height: 100%;
  /* height: 129vh; */
  /* background-position: bottom; */
  /* background-size: cover; */
}

@media only screen and (max-width: 1441px) {
  .image-background-and-cover-config {
    background-size: auto;
    /* background-repeat: no-repeat; */
  }
}

/**
* Old age gate css
*/
#body-inner {
  background: #fff
}

#agegate-outer {
  background-color: #fff;
  /* display: none; */
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%
}

#agegate-outer [type=number],
#agegate-outer [type=text],
#agegate-outer select {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0px;
  -webkit-box-shadow: inset 0 1px 3px #707070;
  box-shadow: inset 0 1px 3px #707070;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* font-family: Helvetica; */
  /* font-size: 1em; */
  margin-bottom: .75em;
  padding: .5em;
}

#agegate-outer [type=number]:hover,
#agegate-outer [type=text]:hover,
#agegate-outer select:hover {
  border-color: shade(#ddd, 20%)
}

#agegate-outer [type=number]:focus,
#agegate-outer [type=text]:focus,
#agegate-outer select:focus {
  border-color: #A86648;
  -webkit-box-shadow: inset 0 1px 3px #707070, 0 0 5px rgba(55, 112, 192, .7);
  box-shadow: inset 0 1px 3px #707070, 0 0 5px rgba(55, 112, 192, .7);
  outline: none
}

#agegate-outer [type=number]:disabled,
#agegate-outer [type=text]:disabled,
#agegate-outer select:disabled {
  background-color: shade(#fff, 5%);
  cursor: not-allowed
}

#agegate-outer [type=number]:disabled:hover,
#agegate-outer [type=text]:disabled:hover,
#agegate-outer select:disabled:hover {
  border: 1px solid #ddd
}

#agegate-outer [type=checkbox] {
  display: inline;
  margin-right: .375em
}

#agegate-outer .agegate-container {
  /* margin-top: 4em; */
  /* padding: 0 1em */
}

#agegate-outer #agegate-errors {
  color: red;
  margin-bottom: .75em;
  width: 100%
}

#agegate-outer .agegate-inner {
  margin: 0 auto;
  /* max-width: 480px; */
  width: 100%
}

#agegate-outer .sr-only {
  /* display: none */
}

#agegate-outer .fields {
  margin-top:
}

#agegate-outer .field-width {
  /* max-width: 433px; */
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#agegate-outer .agegate-fieldset:disabled {
  /* display: none */
}

#agegate-outer .fieldwrapper:first-child {
  padding: 0 10px 0 0
}

#agegate-outer .fieldwrapper:last-child {
  padding: 0 0 0 10px
}

#agegate-outer .fieldwrapper input {
  text-align: center
}

#agegate-outer .fieldwrapper input::placeholder {
  text-align: center
}

#agegate-outer .legal_footer,
#agegate-outer .rememberwrapper,
#agegate-outer .selectwrapper,
#agegate-outer .submitwrapper {
  margin: 0 auto;
  width: 100%
}

#agegate-outer .selectwrapper select {
  height: 36px;
  width: 100%
}

#agegate-outer .rememberwrapper a {
  white-space: nowrap
}

/* #agegate-outer .rememberwrapper p,
#agegate-outer .rememberwrapper a {
  font-size: 0.875rem;
} */

#agegate-outer [type=submit] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #A86648;
  border: 0;
  border-radius: 0px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  padding: 5px 10px;
  width: 128px;
  height: 52px;
}

#agegate-outer [type=submit]:focus,
#agegate-outer [type=submit]:hover {
  /* background-color: shade(#A86648, 20%); */
  color: #A86648 !important;
  border-color: #A86648;
}

#agegate-outer [type=submit]:disabled {
  cursor: not-allowed;
  opacity: .5
}

#agegate-outer [type=submit]:disabled:hover {
  /* background-color: #A86648 */
  color: white !important;
  border-color: white;
}

#agegate-outer {
  min-height: 100vh;
  background: black;
  color: white;
}

.agegate-container input,
.agegate-container select {
  border-radius: 0;
  text-align: center;
  font-size: 1.5rem;
  background: black !important;
  color: white !important;
  padding: 6px 6px !important;
  /* box-sizing: border-box; */
  width: auto;
  height: auto;
}

.agegate-container input[name="agegate-month"],
.agegate-container input[name="agegate-day"] {
  width: 119px;
  height: 76px;
}

.agegate-container input[name="agegate-year"] {
  width: 152px;
  height: 76px;
}

.agegate-container input[name="agegate-month"],
.agegate-container input[name="agegate-day"],
.agegate-container input[name="agegate-year"] {
  font-size: 2.5rem;
  font-family: EastmanCompressed;
  font-weight: 900;
}

@media only screen and (max-width: 768px) {

  .agegate-container input[name="agegate-month"],
  .agegate-container input[name="agegate-day"],
  .agegate-container input[name="agegate-year"] {
    font-size: 1.25rem;
  }

  .agegate-container input[name="agegate-month"],
  .agegate-container input[name="agegate-day"] {
    width: auto;
    min-width: 50px;
    height: auto;
  }

  .agegate-container input[name="agegate-year"] {
    width: auto;
    min-width: 100px;
    height: auto;
  }
}

.agegate-container input[name="agegate-month"]::placeholder,
.agegate-container input[name="agegate-day"]::placeholder,
.agegate-container input[name="agegate-year"]::placeholder {
  color: #707070;
}


#agegate-outer .selectwrapper select {
  height: 48px !important;
  width: 100%
}

#agegate-outer [type=submit] {
  padding: 12px !important;
  margin-top: 8px;
  margin-bottom: 8px;
  border-color: white;
  border: 1px solid white;
  font-family: "DM Sans", "sans-serif";
  font-size: 1.5625rem;
}

#agegate-outer .agegate-container .instructions {
  font-size: 2rem;
}

@media only screen and (max-width: 768px) {
  #agegate-outer .agegate-container .instructions {
    font-size: 1.125rem;
  }
}

#agegate-outer .agegate-container #remember-me-section {
  font-size: 0.875rem;
}

#agegate-outer .agegate-container #remember-me-section .remember-me-container {
  display: flex;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 0.875rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  margin: 0 auto 10px;
  width: 140px;
}


/* Hide the browser's default checkbox */
#agegate-outer .agegate-container #remember-me-section input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #000;
  border: 1px solid #707070;
}

/* On mouse-over, add a grey background color */
#agegate-outer .agegate-container #remember-me-section:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
#agegate-outer .agegate-container #remember-me-section input:checked~.checkmark {
  background-color: black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
#agegate-outer .agegate-container #remember-me-section input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
#agegate-outer .agegate-container #remember-me-section .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Hidding input number arrow */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#agegate-outer .agegate-container a {
  text-decoration: underline;
  font-style: italic;
}

#age-gate-slogan {
  font-size: 2.5rem;
  letter-spacing: 3.5px;
  font-weight: 900;
}

@media only screen and (max-width: 768px) {
  #age-gate-slogan {
    font-size: 1.5625rem;
    letter-spacing: 2.5px;
    font-weight: 900;
  }
}

/* Toggle */
input ~ .dot {
  width: 44px;
  height: 44px;
  left: 2px;
  top: 2px;
}

input:checked ~ .dot {
  transform: translateX(75%);
  /* background-color: #48bb78; */
}

@media only screen and (max-width: 1024px) {
  input ~ .dot {
    width: 22px;
    height: 22px;
    left: 1px;
    top: 1px;
  }
}

@media only screen and (max-width: 1024px) {
  input:checked ~ .dot {
    transform: translateX(70%);
    /* background-color: #48bb78; */
  }
}

input:checked ~ .backdrop {
  background-color: #A86648;
}

/* RC Slider */
.rc-slider {
  position: relative;
  width: 100%;
  height: 14px;
  padding: 5px 0;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #EEF0F6;
  border-radius: 6px;
}
.rc-slider-track {
  position: absolute;
  height: 4px;
  background-color: #EEF0F6;
  border-radius: 6px;
}
.rc-slider-handle {
  position: absolute;
  width: 32px;
  height: 32px;
  margin-top: -14px;
  background-color: #fff;
  border: solid 2px #fff;
  border-radius: 50%;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  /* opacity: 0.8; */
  touch-action: pan-x;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
}
.rc-slider-handle-dragging {
  border-color: #A86648;
  box-shadow: 0 3px 6px 3px rgba(0,0,0,0.3);
  background-color: #A86648;
}
.rc-slider-handle:focus {
  outline: none;
  /* box-shadow: none; */
}
.rc-slider-handle:focus-visible {
  /* border-color: #fff; */
  box-shadow: 0 3px 6px 3px rgba(0,0,0,0.3);
}
.rc-slider-handle-click-focused:focus {
  /* border-color: #fff; */
  box-shadow: unset;
}
.rc-slider-handle:hover {
  /* border-color: #fff; */
}
.rc-slider-handle:active {
  /* border-color: #fff; */
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 0.75rem;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: #999;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
  pointer-events: none;
}
.rc-slider-dot {
  position: absolute;
  bottom: -4px;
  width: 2px;
  height: 12px;
  vertical-align: middle;
  /* background-color: #fff; */
  border: 2px solid #EEF0F6;
  /* border-radius: 50%; */
  cursor: pointer;
}
.rc-slider-dot-active {
  border-color: #EEF0F6;
}
.rc-slider-dot-reverse {
  margin-right: -4px;
}
.rc-slider-disabled {
  background-color: #EEF0F6;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #EEF0F6;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  background-color: #fff;
  border-color: #EEF0F6;
  box-shadow: none;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}
.rc-slider-vertical .rc-slider-rail {
  width: 4px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-track {
  bottom: 0;
  left: 5px;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  margin-top: 0;
  margin-left: -5px;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  width: 4px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-dot {
  margin-left: -2px;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  display: block !important;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-leave {
  display: block !important;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    transform: scale(0, 0);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1, 1);
    transform-origin: 50% 100%;
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform: scale(1, 1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0, 0);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.rc-slider-tooltip {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip-hidden {
  display: none;
}
.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}
.rc-slider-tooltip-inner {
  min-width: 24px;
  height: 24px;
  padding: 6px 2px;
  color: #fff;
  font-size: 0.75rem;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}
.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}


.font-slider-wrapper {
  width: 463px;
}

@media only screen and (max-width: 1024px) {
  .font-slider-wrapper {
    width: 291px;
  }

  .font-slider-wrapper-sm {
    transform: scale(0.6);
  }
}

/* All focus wrapper */
*:focus {
  border: 3px solid white;
}